export const flattenObject = (obj: any) => {
  const flattened: any = {}

  Object.keys(obj).forEach((key: string) => {
    const value = obj[key]

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value))
    } else {
      flattened[key] = value
    }
  })

  return flattened
}
